import { ButtonHTMLAttributes, FC } from 'react';
import { ReactComponent as GoToTopIcon } from '../../assets/svg/footer/go-to-top.svg';

interface ScrollToTopButtonProps
  extends ButtonHTMLAttributes<HTMLButtonElement> {}

export const ScrollToTopButton: FC<ScrollToTopButtonProps> = ({
  className = '',
  ...props
}) => {
  return (
    <button
      onClick={() => window.scroll({ top: 0, left: 0, behavior: 'smooth' })}
      {...props}
      className={className}
    >
      <GoToTopIcon className="h-full w-full" />
    </button>
  );
};
