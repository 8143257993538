import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PNG_Bar from '../../assets/svg/roadmap/roadmap_bar.png';
import JPG_Background from '../../assets/svg/roadmap/roadmap_bg.jpg';
import JPG_Background_Mobile from '../../assets/svg/roadmap/roadmap_bg_mobile.jpg';
import PNG_Content_EN from '../../assets/svg/roadmap/roadmap_content_en.png';
import PNG_Content_KO from '../../assets/svg/roadmap/roadmap_content_ko.png';
import PNG_Content_EN_Mobile from '../../assets/svg/roadmap/roadmap_content_m_en.png';
import PNG_Content_KO_Mobile from '../../assets/svg/roadmap/roadmap_content_m_ko.png';
import PNG_DefaultBG from '../../assets/svg/roadmap/roadmap_default_bg.png';
import { ReactComponent as WhatsTextSVG } from '../../assets/svg/roadmap/roadmap_m_what.svg';
import { ReactComponent as BrushSVG } from '../../assets/svg/roadmap/roadmap_red_brush.svg';

interface RoadmapSectionProps {
  sectionRef?: any;
}

export const RoadmapSection: FC<RoadmapSectionProps> = ({ sectionRef }) => {
  const { i18n } = useTranslation();

  return (
    <div ref={sectionRef}>
      <div className="hidden overflow-hidden md:block">
        <div
          className="relative h-[800px] bg-center"
          style={{
            backgroundImage: `url('${JPG_Background}')`,
          }}
        >
          {i18n.language === 'en' ? (
            <img
              src={PNG_Content_EN}
              alt=""
              className="absolute bottom-0 left-1/2 mx-auto max-w-6xl -translate-x-1/2 transform"
            />
          ) : (
            <img
              src={PNG_Content_KO}
              alt=""
              className="absolute bottom-0 left-1/2 mx-auto max-w-6xl -translate-x-1/2 transform"
            />
          )}
        </div>
        <div className="relative h-10">
          <img
            src={PNG_Bar}
            alt=""
            className=" absolute h-full w-full object-cover"
          />
        </div>
      </div>

      <div className="block overflow-hidden md:hidden">
        <div
          className="flex h-24 flex-col bg-[#222222]"
          style={{
            backgroundImage: `url('${PNG_DefaultBG}')`,
          }}
        >
          <div className="flex-1" />
          <WhatsTextSVG className="gird ml-auto mr-20 h-10 w-28 sm:w-32" />
          <div className="relative h-7">
            <img
              src={PNG_Bar}
              alt=""
              className="absolute h-full w-full object-cover object-left"
            />
          </div>
        </div>
        <div
          className="relative bg-center"
          style={{
            backgroundImage: `url('${JPG_Background_Mobile}')`,
          }}
        >
          <div className="-mx-10 sm:mx-0">
            {i18n.language === 'en' ? (
              <img src={PNG_Content_EN_Mobile} alt="" className="w-full pl-3" />
            ) : (
              <img src={PNG_Content_KO_Mobile} alt="" className="w-full pl-3" />
            )}
          </div>
        </div>
        <div className="relative h-12">
          <div className="relative h-7">
            <img
              src={PNG_Bar}
              alt=""
              className=" absolute h-full w-full object-cover object-right"
            />
          </div>
          <BrushSVG className="absolute right-10 bottom-0 z-10 h-16 w-20" />
          <div className="absolute bottom-0 h-5 w-full bg-[#222222]" />
        </div>
      </div>
    </div>
  );
};
