import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import { Admin } from './admin/Admin';
import { AdminRoute } from './components/AdminRoute';
import { ScrollToTop } from './components/ScrollToTop';
import { useWeb3 } from './hooks/web3';
import { Router } from './pages/Router';
import 'react-toastify/dist/ReactToastify.css';

function App() {
  useWeb3();
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Routes>
        <Route path="admin/*" element={<AdminRoute element={<Admin />} />} />
        <Route path="*" element={<Router />} />
      </Routes>
      <ToastContainer
        autoClose={3000}
        hideProgressBar
        limit={1}
        onClick={() => toast.clearWaitingQueue()}
      />
    </BrowserRouter>
  );
}

export default App;
