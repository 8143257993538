import { ButtonHTMLAttributes, FC } from 'react';

interface TermsButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  className?: string;
}

export const TermsButton: FC<TermsButtonProps> = ({ className, ...props }) => {
  return (
    <button
      onClick={() =>
        window.open(
          'https://elderly-paste-700.notion.site/HERO-DUCK-TERMS-CONDITIONS-edcaee54955e4cd182b6bbc7fc23fe5c'
        )
      }
      {...props}
      className={className}
    >
      Terms {`&`} Conditions
    </button>
  );
};
