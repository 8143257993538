import { FC, useEffect } from 'react';
import { useQuery } from 'react-query';
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../hooks';
import { fetcher } from '../plugins/react-query';
import { Role, User } from '../types';

export interface AdminRouteProps {
  element: JSX.Element;
}

export const AdminRoute: FC<AdminRouteProps> = ({ element: Element }) => {
  const navigate = useNavigate();
  const { authenticated } = useAuth();
  const { data: me, status } = useQuery<User>('/users/me', fetcher, {
    enabled: authenticated,
  });

  useEffect(() => {
    if (!authenticated) {
      navigate('/login', { replace: true });
      return;
    }
    if (status !== 'success' || !me || me.role !== Role.ADMIN) {
      navigate('/login', { replace: true });
      return;
    }
  }, [authenticated, me, status]);

  if (status === 'loading') {
    return <></>;
  }

  return Element;
};
