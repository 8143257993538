import { FC } from 'react';
import { Icon } from '../Icon';

interface DiscordLinkProps {
  className?: string;
}

export const DiscordLink: FC<DiscordLinkProps> = ({ className }) => {
  return (
    <button
      className={className}
      onClick={() => {
        window.open('https://discord.gg/mQZJbxKykc', '_blank');
      }}
    >
      <Icon.Discord className="h-full w-full" />
    </button>
  );
};
