import { useRef, useState } from 'react';
import { Footer } from '../components/Footer/Footer';
import { GNB } from '../components/Navbar/GNB';
import { FAQSection } from './sections/FAQSection';
import { GallerySection } from './sections/GallerySection';
import { HeroSection } from './sections/HeroSection';
import { NFTismOneSection } from './sections/NFTismOneSection';
import { NFTismSecondSection } from './sections/NFTismSecondSection';
import { NFTismThirdSection } from './sections/NFTismThirdSection';
import { RoadmapSection } from './sections/RoadmapSection';
import { TeamSection } from './sections/TeamSection';

enum SectionTarget {
  PROJECT = 'PROJECT',
  GALLERY = 'GALLERY',
  NFTISM = 'NFTISM',
  ROADMAP = 'ROADMAP',
  TEAM = 'TEAM',
  FAQ = 'FAQ',
}

export const LandingPage = () => {
  const projectSection = useRef<any>();
  const gallerySection = useRef<any>();
  const nftismSection = useRef<any>();
  const roadmapSection = useRef<any>();
  const teamSection = useRef<any>();
  const faqSection = useRef<any>();
  const [selectedTab, setSelectedTab] = useState<SectionTarget>(
    SectionTarget.PROJECT
  );

  const handleScroll = (scrollTarget: SectionTarget) => {
    let tempTarget: any;
    setSelectedTab(scrollTarget);
    switch (scrollTarget) {
      case SectionTarget.PROJECT:
        tempTarget = projectSection;
        break;
      case SectionTarget.GALLERY:
        tempTarget = gallerySection;
        break;
      case SectionTarget.NFTISM:
        tempTarget = nftismSection;
        break;
      case SectionTarget.ROADMAP:
        tempTarget = roadmapSection;
        break;
      case SectionTarget.TEAM:
        tempTarget = teamSection;
        break;
      case SectionTarget.FAQ:
        tempTarget = faqSection;
        break;
      default:
        console.log(`Something went wrong.`);
    }
    const yOffset = -100;
    const calculatedTop =
      tempTarget.current.getBoundingClientRect().top +
      window.pageYOffset +
      yOffset;

    window.scrollTo({ top: calculatedTop, behavior: 'smooth' });
  };

  return (
    <div className="relative flex flex-col">
      <GNB
        onClickProject={() => handleScroll(SectionTarget.PROJECT)}
        onClickGallery={() => handleScroll(SectionTarget.GALLERY)}
        onClickNFTism={() => handleScroll(SectionTarget.NFTISM)}
        onClickRoadmap={() => handleScroll(SectionTarget.ROADMAP)}
        onClickTeam={() => handleScroll(SectionTarget.TEAM)}
        onClickFAQ={() => handleScroll(SectionTarget.FAQ)}
      />

      {/* <MintingSection /> */}
      <div className="hidden h-6 w-full bg-black md:block" />
      <HeroSection />
      <div className="hidden h-6 w-full bg-black md:block" />
      <NFTismOneSection />
      <NFTismSecondSection sectionRef={projectSection} />
      <NFTismThirdSection sectionRef={nftismSection} />
      <div className="h-6 w-full bg-black " />
      <GallerySection sectionRef={gallerySection} />
      <RoadmapSection sectionRef={roadmapSection} />
      <TeamSection sectionRef={teamSection} />

      <FAQSection sectionRef={faqSection} />
      <div className="h-6 bg-brand-1 md:h-40" />

      <Footer />
    </div>
  );
};
