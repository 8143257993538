import * as React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { ReactComponent as DownArrow } from '../../assets/svg/down-arrow.svg';
import { ReactComponent as Faq } from '../../assets/svg/menu/faq.svg';
import { ReactComponent as Gallery } from '../../assets/svg/menu/gallery.svg';
import { ReactComponent as Nftism } from '../../assets/svg/menu/nftism.svg';
import { ReactComponent as Project } from '../../assets/svg/menu/project.svg';
import { ReactComponent as Roadmap } from '../../assets/svg/menu/roadmap.svg';
import { ReactComponent as Team } from '../../assets/svg/menu/team.svg';
import { Button } from '../Button';
import { DiscordLink } from '../SocialLinks/DiscordLink';
import { InstagramLink } from '../SocialLinks/InstagramLink';
import { TwitterLink } from '../SocialLinks/TwitterLink';

const variants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const variants2 = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

interface MobileGNBProps {
  onClickProject: () => void;
  onClickGallery: () => void;
  onClickNFTism: () => void;
  onClickRoadmap: () => void;
  onClickTeam: () => void;
  onClickFAQ: () => void;
  toggle: () => void;
  isOpen: boolean;
}

const menu = [
  { id: 1, text: <Project /> },
  { id: 2, text: <Gallery /> },
  { id: 3, text: <Nftism /> },
  { id: 4, text: <Roadmap /> },
  { id: 5, text: <Team /> },
  { id: 6, text: <Faq /> },
];

export const MobileGNB: React.FC<MobileGNBProps> = ({
  onClickProject,
  onClickGallery,
  onClickNFTism,
  onClickRoadmap,
  onClickTeam,
  onClickFAQ,
  toggle,
  isOpen,
}) => {
  const [isENSelected, setIsENSelected] = React.useState(
    window.localStorage.getItem('language') === 'en'
  );
  const { t, i18n } = useTranslation();
  const setLanguage = (language: string) => {
    i18n.changeLanguage(language);
  };

  return (
    <motion.ul
      variants={variants}
      className={`top-10 z-30 w-full p-4 ${isOpen ? 'absolute' : 'hidden'}`}
    >
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={1}
        onClick={() => {
          onClickProject();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Project />
        </div>
      </motion.li>
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={2}
        onClick={() => {
          onClickGallery();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Gallery />
        </div>
      </motion.li>
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={3}
        onClick={() => {
          onClickNFTism();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Nftism />
        </div>
      </motion.li>
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={4}
        onClick={() => {
          onClickRoadmap();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Roadmap />
        </div>
      </motion.li>
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={5}
        onClick={() => {
          onClickTeam();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Team />
        </div>
      </motion.li>
      <motion.li
        variants={variants2}
        whileTap={{ scale: 0.95 }}
        key={6}
        onClick={() => {
          onClickFAQ();
          toggle();
        }}
      >
        <div className="w-full border-b-2 border-black pt-6 pb-10">
          <Faq />
        </div>
      </motion.li>

      <motion.li
        variants={variants2}
        key={7}
        className="relative w-full border-b-2 border-black py-8"
      >
        <div className="absolute right-6 bottom-6">
          <div className="relative flex flex-col">
            <Button
              onClick={() => setLanguage('en')}
              className={`${
                i18n.language === 'en'
                  ? 'bg-black text-white'
                  : 'border-[3px] border-black bg-white'
              } z-10 grid h-[64px] w-[64px]  place-content-center rounded-full`}
            >
              ENG
              {i18n.language === 'en' && <DownArrow className="mx-auto" />}
            </Button>
            <div className="absolute top-12 right-[1.8rem] h-12 w-[0.2rem] bg-black"></div>
            <Button
              onClick={() => setLanguage('ko')}
              className={`${
                i18n.language === 'en'
                  ? 'border-[3px] border-black bg-white'
                  : 'bg-black text-white'
              } z-10 mt-2 grid h-[64px] w-[64px] place-content-center rounded-full`}
            >
              KOR
              {i18n.language !== 'en' && <DownArrow className="mx-auto" />}
            </Button>
          </div>
        </div>

        <div className="mr-32 flex max-w-[200px] justify-between">
          <InstagramLink />
          <TwitterLink />
          <DiscordLink />
        </div>
      </motion.li>
    </motion.ul>
  );
};
