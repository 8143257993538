import { newRidgeState } from 'react-ridge-state';
import { User } from '../types';
import { api } from './axios';

export const hashListState = newRidgeState<string[]>([]);
export const meState = newRidgeState<User | null>(null);
export const tokenState = newRidgeState<string | null>(null, {
  onSet: async (newState) => {
    if (newState) {
      localStorage.setItem('token', newState);
      const result = await api.get('/users/me').catch((e) => {
        console.log('tokenState error : ', e);
        localStorage.removeItem('token');
      });
      if (result && (result.status === 200 || result.status === 201)) {
        meState.set(result.data);
      }
    } else {
      meState.reset();
      localStorage.removeItem('token');
    }
  },
});

export const osState = newRidgeState<string>('');

function setInitialState() {
  const token = localStorage.getItem('token');
  tokenState.set(token);
}

setInitialState();
