import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PNG_Duck from '../../assets/svg/faq/duck.png';
import PNG_Brush from '../../assets/svg/faq/faq_brush.png';
import PNG_Duck_Mobile from '../../assets/svg/faq/faq_duck_m.png';
import PNG_FAQ_EN from '../../assets/svg/faq/faq_en.png';
import PNG_FAQ_EN_Mobile from '../../assets/svg/faq/faq_en_mobile.png';
import PNG_FAQ_KO from '../../assets/svg/faq/faq_ko.png';
import PNG_FAQ_KO_Mobile from '../../assets/svg/faq/faq_kr_mobile.png';
import PNG_RightBrushes from '../../assets/svg/faq/faq_right.png';
import PNG_Title_Mobile from '../../assets/svg/faq/title-mobile.png';

interface FAQSectionProps {
  sectionRef?: any;
}

export const FAQSection: FC<FAQSectionProps> = ({ sectionRef }) => {
  const { i18n } = useTranslation();

  return (
    <div ref={sectionRef}>
      <div className="hidden overflow-hidden md:block">
        <div className="flex justify-center bg-brand-1">
          <div className="relative -mx-20 grid max-w-screen-lg grid-cols-3 place-items-end gap-x-10">
            {i18n.language === 'en' ? (
              <img src={PNG_FAQ_EN} alt="" className="col-span-2" />
            ) : (
              <img src={PNG_FAQ_KO} alt="" className="col-span-2" />
            )}
            <img src={PNG_Duck} alt="" className=" " />
          </div>
        </div>
      </div>

      <div className="block overflow-hidden bg-brand-1 md:hidden">
        <div className="relative my-10  sm:h-[740px]">
          <img
            src={PNG_Title_Mobile}
            alt=""
            className="absolute top-0 left-3 z-40 w-[80%]"
          />
          <img
            src={PNG_RightBrushes}
            alt=""
            className="absolute -right-8 top-0 h-fit w-3/12 sm:w-fit"
          />

          <img
            src={PNG_Brush}
            alt=""
            className="absolute -left-10 bottom-24 w-2/12 sm:w-fit"
          />
          {i18n.language === 'en' ? (
            <>
              <img
                src={PNG_FAQ_EN_Mobile}
                alt=""
                className="invisible w-11/12 max-w-md pt-44"
              />
              <img
                src={PNG_FAQ_EN_Mobile}
                alt=""
                className="absolute left-1/2 top-28 z-10 w-11/12 max-w-md -translate-x-1/2"
              />
            </>
          ) : (
            <>
              <img
                src={PNG_FAQ_KO_Mobile}
                alt=""
                className="invisible w-11/12 max-w-md pt-44"
              />
              <img
                src={PNG_FAQ_KO_Mobile}
                alt=""
                className="absolute left-1/2 top-28 z-10 w-full max-w-md -translate-x-1/2"
              />
            </>
          )}
          <img
            src={PNG_Duck_Mobile}
            alt=""
            className="absolute -right-5 bottom-0 w-4/12 sm:w-fit"
          />
        </div>
      </div>
    </div>
  );
};
