import { ButtonHTMLAttributes, FC } from 'react';
import { ReactComponent as OpenSeaSVG } from '../../assets/svg/open-sea.svg';

interface OpenSeaButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  externalURL: string;
}

export const OpenSeaButton: FC<OpenSeaButtonProps> = ({
  externalURL,
  className = '',
  ...props
}) => {
  return (
    <button
      onClick={() => {
        window.open(externalURL, '_blank');
      }}
      {...props}
      className={className}
    >
      <OpenSeaSVG />
    </button>
  );
};
