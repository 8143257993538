import { InjectedConnector } from '@web3-react/injected-connector';
import { WalletConnectConnector } from '@web3-react/walletconnect-connector';
import { WalletLinkConnector } from '@web3-react/walletlink-connector';

const injected = new InjectedConnector({
  supportedChainIds: [1],
});

const walletConnect = new WalletConnectConnector({
  //@ts-ignore
  rpcUrl: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  bridge: 'https://bridge.walletconnect.org',
  qrcode: true,
});

const coinbaseWallet = new WalletLinkConnector({
  url: `https://mainnet.infura.io/v3/${process.env.REACT_APP_INFURA_KEY}`,
  appName: 'heroduck',
});

export const connectors: {
  [key: string]:
    | InjectedConnector
    | WalletConnectConnector
    | WalletLinkConnector;
} = {
  injected,
  walletConnect,
  coinbaseWallet,
};
