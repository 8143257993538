import { FC } from 'react';
import hero from '../../assets//main.gif';
import { ReactComponent as BrushSVG1 } from '../../assets/svg/hero/1.svg';
import { ReactComponent as BrushSVG2 } from '../../assets/svg/hero/2.svg';
// import { ReactComponent as HeroSVG } from '../../assets/svg/hero/hero.svg';
import { ReactComponent as Brush_B } from '../../assets/svg/hero/main_brush_b.svg';
import { ReactComponent as Brush_G } from '../../assets/svg/hero/main_brush_g.svg';
import { ReactComponent as Brush_R } from '../../assets/svg/hero/main_brush_r.svg';
import { ReactComponent as Brush_Y } from '../../assets/svg/hero/main_brush_y.svg';
import { ReactComponent as TextBottomSVG } from '../../assets/svg/hero/text-bottom.svg';
import { ReactComponent as TextLeftSVG } from '../../assets/svg/hero/text-left.svg';
import { ReactComponent as TextRightSVG } from '../../assets/svg/hero/text-right.svg';
import { ReactComponent as TextTopSVG } from '../../assets/svg/hero/text-top.svg';

interface HeroSectionProps {
  ref?: any;
}

export const HeroSection: FC<HeroSectionProps> = ({ ref }) => {
  return (
    <div ref={ref}>
      <div className="hidden md:block">
        <div className="relative h-[880px] bg-brand-1">
          <BrushSVG1 className="absolute" />
          <BrushSVG2 className="absolute right-0 bottom-14" />

          <div className="flex flex-col items-center justify-center pt-10">
            <TextTopSVG className="z-10 mb-5" />

            <div className="flex items-center space-x-10">
              <TextLeftSVG className="z-10 ml-5" />
              {/* <HeroSVG className="mx-auto" /> */}
              <div className="relative flex-1 space-y-5">
                <div className="absolute -left-3 top-2 bg-brand-1 p-3">
                  <div className="wh-2 rotate-45 transform bg-black" />
                </div>
                <div className="absolute right-0 top-0 bg-brand-1 p-3" />

                <div className="h-0.5 w-full bg-black" />

                <div className="flex items-center space-x-5">
                  <div className="h-[600px] w-0.5 flex-shrink-0 bg-black" />
                  <img
                    src={hero}
                    alt=""
                    className=" h-[600px] flex-1 overflow-hidden object-cover"
                  />
                  <div className="h-[600px] w-0.5 flex-shrink-0 bg-black" />
                </div>

                <div className="h-0.5 w-full bg-black" />

                <div className="absolute -right-3.5 -bottom-3.5 bg-brand-1 p-3">
                  <div className="wh-2 rotate-45 transform bg-black" />
                </div>
                <div className="absolute left-0 bottom-0 bg-brand-1 p-3" />
              </div>
              <div className="z-10">
                <TextRightSVG className="mr-5" />
              </div>
            </div>

            <TextBottomSVG className="z-10 my-5" />
          </div>
        </div>
      </div>

      <div className="block overflow-hidden md:hidden">
        <div className="relative h-[830px] bg-brand-1">
          <Brush_B className="absolute -right-10 top-72 h-20 w-24" />
          <Brush_G className="absolute bottom-80 -left-10 z-10 h-12 w-44" />
          <Brush_R className="absolute -left-8 top-20" />
          <Brush_Y className="w-30 absolute -top-6 left-12 h-24" />

          <div className="flex flex-col items-center justify-center pt-20">
            <TextTopSVG className="z-10 h-6" />

            <div className="flex items-center space-x-3">
              <TextLeftSVG className="z-10 ml-5 h-fit w-6 pb-56" />
              {/* <HeroSVG className="mx-auto" /> */}
              <div className="relative flex-1 space-y-3">
                <div className="absolute left-0 top-0 h-5 w-3 bg-brand-1" />
                <div className="absolute right-0 top-0 h-1 w-3 bg-brand-1" />

                <div className="h-0.5 w-full bg-black" />

                <div className="flex items-center space-x-3">
                  <div className="h-[600px] w-0.5 flex-shrink-0 bg-black" />
                  <img
                    src={hero}
                    alt=""
                    className=" h-[600px] flex-1 overflow-hidden object-cover"
                  />
                  <div className="h-[600px] w-0.5 flex-shrink-0 bg-black" />
                </div>

                <div className="h-0.5 w-full bg-black" />

                <div className="absolute right-0 bottom-0 h-1 w-3 bg-brand-1" />
                <div className="absolute left-0 bottom-0 h-1 w-3 bg-brand-1" />
              </div>
              <div className="z-10">
                <TextRightSVG className="mr-5 h-fit w-6 pt-72" />
              </div>
            </div>

            <TextBottomSVG className="z-10 my-5 h-6" />
          </div>
        </div>
      </div>
    </div>
  );
};
