import { useEffect } from 'react';
import { useWeb3React } from '@web3-react/core';
import { connectors } from '../connectors';
import { toHex } from '../utils';

export function useWeb3() {
  const { library, activate, deactivate } = useWeb3React();

  const switchNetwork = async (network: string) => {
    try {
      await library.provider.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(network) }],
      });
    } catch (switchError) {
      console.log('switchError', switchError);
      // 추후에 만약 메타마스크에서 기본적으로 지원하지 않는 네트워크 추가를 할 때 필요
      // if (switchError.code === 4902) {
      //   try {
      //     await library.provider.request({
      //       method: 'wallet_addEthereumChain',
      //       params: [networkParams[toHex(network)]],
      //     });
      //   } catch (error) {
      //     console.log('switchNetwork error: ',error)
      //   }
      // }
    }
  };

  const switchMetaMaskNetwork = async (chainId: string) => {
    if (window.ethereum && window.ethereum.networkVersion !== chainId)
      await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: toHex(chainId) }],
      });
  };

  const refreshState = () => {
    window.localStorage.setItem('provider', '');
  };

  const disconnect = () => {
    refreshState();
    deactivate();
  };

  useEffect(() => {
    // switchMetaMaskNetwork(ETH_MAIN_NET);
    const provider = window.localStorage.getItem('provider');
    if (provider) activate(connectors[provider]);
  }, []);
}
