import * as React from 'react';
import { motion } from 'framer-motion';

const Path = ({ ...props }) => (
  <motion.path
    fill="transparent"
    strokeWidth="3"
    stroke="hsl(0, 0%, 18%)"
    strokeLinecap="round"
    {...props}
  />
);

interface MobileGNBButton {
  toggle: () => void;
}

export const MobileGNBButton: React.FC<MobileGNBButton> = ({ toggle }) => (
  <button
    onClick={toggle}
    className="button_222 wh-[50px] top-6 right-6 z-40 bg-black p-4"
  >
    <svg width="20" height="20" viewBox="0 0 20 20">
      <Path
        className="stroke-current text-white"
        variants={{
          closed: { d: 'M 2 2.5 L 20 2.5' },
          open: { d: 'M 3 16.5 L 17 2.5' },
        }}
      />
      <Path
        className="stroke-current text-white"
        d="M 2 9.423 L 20 9.423"
        variants={{
          closed: { opacity: 1 },
          open: { opacity: 0 },
        }}
        transition={{ duration: 0.1 }}
      />
      <Path
        className="stroke-current text-white"
        variants={{
          closed: { d: 'M 2 16.346 L 20 16.346' },
          open: { d: 'M 3 2.5 L 17 16.346' },
        }}
      />
    </svg>
  </button>
);
