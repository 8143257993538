import { FC, useEffect } from 'react';
import { motion, useAnimation } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { ReactComponent as SmallTextSVG } from '../../assets/svg/nftism/small-text.svg';
import PNG_Window1 from '../../assets/svg/nftism/window-1.png';
import PNG_Window2 from '../../assets/svg/nftism/window-2.png';
import PNG_Window3_EN from '../../assets/svg/nftism/window-3.png';
import PNG_Window3_KO from '../../assets/svg/nftism/window-3_ko.png';
import PNG_Window1_EN_Mobile from '../../assets/svg/nftism/window_m_1.png';
import PNG_Window2_Mobile from '../../assets/svg/nftism/window_m_2.png';
import PNG_Window3_Mobile from '../../assets/svg/nftism/window_m_3.png';
import PNG_Window3_KO_Mobile from '../../assets/svg/nftism/window_m_ko_1.png';

const container = {
  hidden: { opacity: 1, scale: 0 },
  visible: {
    opacity: 1,
    scale: 1,
    transition: {
      delayChildren: 0.25,
      staggerChildren: 0.2,
    },
  },
};

const item = {
  hidden: { y: 20, opacity: 0 },
  visible: {
    y: 0,
    opacity: 1,
  },
};

interface NFTismSecondSectionProps {
  sectionRef?: any;
}

export const NFTismSecondSection: FC<NFTismSecondSectionProps> = ({
  sectionRef,
}) => {
  const { i18n } = useTranslation();

  const controls = useAnimation();
  const [ref, inView] = useInView();
  useEffect(() => {
    if (inView) {
      controls.start('visible');
    } else {
      controls.start('hidden');
    }
  }, [controls, inView]);
  return (
    <div
      className="overflow-hidden bg-[#222222] py-5 md:pb-10"
      ref={sectionRef}
    >
      <motion.ul
        ref={ref}
        animate={controls}
        initial="hidden"
        className="relative mx-auto h-[510px] w-full max-w-screen-lg md:h-[820px]"
        variants={container}
      >
        <motion.li variants={item}>
          <img
            src={PNG_Window1}
            alt=""
            className="absolute top-10 right-10 z-30 hidden h-fit md:block"
          />
          <img
            src={PNG_Window3_Mobile}
            alt=""
            className="absolute top-0 left-10 block md:hidden"
          />
        </motion.li>

        <motion.li variants={item}>
          <img
            src={PNG_Window2}
            alt=""
            className="absolute top-1/2 left-10 z-30 hidden -translate-y-1/2 md:block"
          />
          <img
            src={PNG_Window2_Mobile}
            alt=""
            className="absolute top-10 right-10 block md:hidden"
          />
        </motion.li>

        <motion.li variants={item}>
          {i18n.language === 'en' ? (
            <img
              src={PNG_Window3_EN}
              alt=""
              className="absolute bottom-10 left-1/2 z-30 hidden -translate-x-1/2 md:block"
            />
          ) : (
            <img
              src={PNG_Window3_KO}
              className="absolute bottom-10 left-1/2 z-30 hidden -translate-x-1/2 md:block"
            />
          )}
          {i18n.language === 'en' ? (
            <img
              src={PNG_Window1_EN_Mobile}
              alt=""
              className="absolute bottom-12 left-1/2 block w-[21rem] -translate-x-1/2 md:hidden"
            />
          ) : (
            <img
              src={PNG_Window3_KO_Mobile}
              alt=""
              className="absolute bottom-12 left-1/2 block w-[21rem] -translate-x-1/2 md:hidden"
            />
          )}
        </motion.li>

        <SmallTextSVG className="absolute bottom-5 right-10 md:bottom-5 lg:-mr-20" />
      </motion.ul>
    </div>
  );
};
