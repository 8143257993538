import { FC } from 'react';
import { ReactComponent as LeftBGSVG } from '../../assets/svg/nftism/1.svg';
import { ReactComponent as RightBGSVG } from '../../assets/svg/nftism/2.svg';
import { ReactComponent as BrushBlueSVG } from '../../assets/svg/nftism/brush_b_m.svg';
import { ReactComponent as BrushRedSVG } from '../../assets/svg/nftism/brush_r_m.svg';
import { ReactComponent as BrushYellowSVG } from '../../assets/svg/nftism/brush_y_m.svg';
import { ReactComponent as DuckSVG } from '../../assets/svg/nftism/duck.svg';
import PNG_DUCK from '../../assets/svg/nftism/ducky.png';
import { ReactComponent as MiddleTextSVG } from '../../assets/svg/nftism/middle_text_mobile.svg';
import { ReactComponent as TextSVG } from '../../assets/svg/nftism/text-2.svg';
import { ReactComponent as TopTextSVG } from '../../assets/svg/nftism/text.svg';
import { ReactComponent as TopTextSVG_M_2 } from '../../assets/svg/nftism/top_text2_mobile.svg';
import { ReactComponent as TopTextSVG_M } from '../../assets/svg/nftism/top_text_mobile.svg';
import { GlitchText } from '../../components/GlitchText/GlitchText';

interface NFTismOneSectionProps {
  ref?: any;
}

export const NFTismOneSection: FC<NFTismOneSectionProps> = ({ ref }) => {
  return (
    <div ref={ref}>
      <div className="hidden md:block">
        <div className="relative bg-brand-1 pt-10 pb-24">
          <div className="flex w-full justify-center overflow-hidden">
            <TopTextSVG className=" h-fit w-full min-w-max max-w-screen-lg" />
          </div>

          <div className="mx-auto -mt-20 flex max-w-screen-lg justify-between space-x-20">
            <div className="relative">
              <GlitchText />
              <TextSVG className="z-20 h-fit" />
            </div>
            <DuckSVG className="z-20 mt-20 h-fit" />
          </div>

          <LeftBGSVG className="absolute bottom-0 z-10 h-fit w-20" />
          <RightBGSVG className="absolute right-0 bottom-10 h-fit w-20" />
          <div className="absolute bottom-0 h-7 w-full bg-[#222222]" />
        </div>
      </div>

      <div className="relative block overflow-x-hidden bg-brand-1 pb-10 md:hidden">
        <div className="-mx-5">
          <TopTextSVG_M className="h-fit w-full" />
        </div>
        <div className="wh-2 absolute top-5 right-1/3 rotate-45 transform bg-black" />

        <div className="relative flex h-[9.5rem] justify-center">
          <GlitchText />
          <TopTextSVG_M_2 className="absolute -mt-5 h-fit w-full" />
        </div>

        <div className="mx-auto flex items-center">
          <MiddleTextSVG className="h-full w-full px-5" />
          <img src={PNG_DUCK} alt="" className="w-1/2" />
        </div>

        <BrushYellowSVG className="absolute bottom-[6%] -right-10 w-28" />
        <BrushBlueSVG className="absolute top-56 -left-10 w-24" />
        <BrushRedSVG className="absolute bottom-2 -left-10 z-20 w-28" />
        <div className="absolute bottom-0 h-6 w-full bg-[#222222]" />
      </div>
    </div>
  );
};
