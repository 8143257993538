import { ReactComponent as DuckIcon } from '../../assets/svg/footer/footer-icon.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/footer/logo.svg';
import { ScrollToTopButton } from '../Button/ScrollToTopButton';
import { TermsButton } from '../Button/TermsButton';
import { DiscordLink } from '../SocialLinks/DiscordLink';
import { InstagramLink } from '../SocialLinks/InstagramLink';
import { TwitterLink } from '../SocialLinks/TwitterLink';

export const Footer = () => {
  return (
    <div className="bg-brand-1 md:pb-10">
      <div className="relative flex w-full items-center">
        <DuckIcon className="absolute left-5 hidden lg:block" />

        <div className="relative flex h-12 w-full flex-1 justify-between divide-x divide-black border-t border-b border-black lg:mx-12 lg:h-16">
          <div className="flex w-full items-end md:mb-3">
            <LogoIcon className="my-auto ml-4 w-[70%] md:ml-14 md:mr-10 md:mt-3 md:w-auto" />

            <div className="mr-4 hidden flex-1 justify-between md:flex">
              <p className="text-[.45rem] line-clamp-1 md:text-xs">
                © Hero Duck. All Rights Reserved.
              </p>
              <TermsButton className="hidden text-[.45rem] md:block md:text-sm" />
            </div>
          </div>

          <div className="flex items-center space-x-4 pr-2 pl-8 md:space-x-10 md:px-20">
            <InstagramLink className="wh-6 md:wh-8" />
            <TwitterLink className="wh-6 md:wh-8" />
            <DiscordLink className="wh-6 md:wh-8" />
          </div>
        </div>

        <ScrollToTopButton className="wh-12 absolute right-[7.6rem] -top-6 sm:right-[8.1rem] md:wh-16 md:top-0 md:right-4" />
      </div>

      <div className="my-3">
        <TermsButton className="mx-auto block text-center text-[.8rem] md:hidden" />
        <p className="block text-center text-[.45rem] sm:hidden">
          © Hero Duck. All Rights Reserved.
        </p>
      </div>
    </div>
  );
};
