import { Route, Routes } from 'react-router-dom';
import { Sidebar } from '../components/Sidebar';
import { useAuth } from '../hooks';
import AdminMain from './components/AdminMain';
import { UserEdit } from './pages/UserEdit';
import { UserList } from './pages/UserList';
import { UserShow } from './pages/UserShow';

export const Admin = () => {
  const { logout } = useAuth();
  return (
    <div className="flex h-screen">
      <Sidebar>
        <Sidebar.Title>Admin</Sidebar.Title>
        <Sidebar.Menu>
          <Sidebar.Menu.Item text="유저리스트" to="/admin/users" />
          <Sidebar.Menu.Item text="로그아웃" onClick={logout} />
        </Sidebar.Menu>
      </Sidebar>

      <div className="flex min-w-0 flex-1 flex-col overflow-hidden">
        <AdminMain>
          <Routes>
            <Route path="users/*" element={<UserList />} />
            <Route path="users/:id/edit" element={<UserEdit />} />
            <Route path="users/:id" element={<UserShow />} />
          </Routes>
        </AdminMain>
      </div>
    </div>
  );
};
