import { FC, useRef, useState } from 'react';
import { motion, useCycle } from 'framer-motion';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { ReactComponent as DownArrow } from '../../assets/svg/down-arrow.svg';
import { ReactComponent as LogoIcon } from '../../assets/svg/footer/logo.svg';
import { Button } from '../Button';
import { OpenSeaButton } from '../Button/OpenSeaButton';
import { DiscordLink } from '../SocialLinks/DiscordLink';
import { InstagramLink } from '../SocialLinks/InstagramLink';
import { TwitterLink } from '../SocialLinks/TwitterLink';
import { MobileGNB as MobileGNB } from './MobileGNB';
import { MobileGNBButton } from './MobileGNBButton';

interface GNBProps {
  onClickProject: () => void;
  onClickGallery: () => void;
  onClickNFTism: () => void;
  onClickRoadmap: () => void;
  onClickTeam: () => void;
  onClickFAQ: () => void;
}

export const GNB: FC<GNBProps> = ({
  onClickProject,
  onClickGallery,
  onClickNFTism,
  onClickRoadmap,
  onClickTeam,
  onClickFAQ,
}) => {
  const navigate = useNavigate();
  const [isENSelected, setIsENSelected] = useState(
    window.localStorage.getItem('language') === 'en'
  );
  const { i18n } = useTranslation();

  const [isOpen, toggleOpen] = useCycle(false, true);
  const containerRef = useRef(null);
  // const { height } = useDimensions(containerRef);

  const sidebar = {
    open: (height = 1000) => ({
      clipPath: `circle(${height * 2 + 200}px at 130% -30px)`,
      transition: {
        type: 'spring',
        stiffness: 20,
        restDelta: 2,
      },
    }),
    closed: {
      clipPath: 'circle(30px at 130% -30px)',
      transition: {
        delay: 0.5,
        type: 'spring',
        stiffness: 400,
        damping: 40,
      },
    },
  };

  return (
    <>
      <div className="sticky top-0 z-40">
        <div className="hidden bg-brand-1 px-5 py-6 lg:block">
          <div className="absolute">
            <div className="relative flex">
              <Button
                onClick={() => i18n.changeLanguage('en')}
                className={`${i18n.language === 'en'
                    ? 'bg-black text-white'
                    : 'border-[3px] border-black bg-white'
                  } z-10 grid h-[64px] w-[64px]  place-content-center rounded-full`}
              >
                ENG
                {i18n.language === 'en' && <DownArrow className="mx-auto" />}
              </Button>
              <div className="absolute top-12 left-12 h-[3px] w-6 rotate-45 bg-black"></div>
              <Button
                onClick={() => i18n.changeLanguage('ko')}
                className={`${i18n.language === 'en'
                    ? 'border-[3px] border-black bg-white'
                    : 'bg-black text-white'
                  } z-10 mt-10 grid h-[64px] w-[64px] place-content-center rounded-full`}
              >
                KOR
                {i18n.language === 'ko' && <DownArrow className="mx-auto" />}
              </Button>
            </div>
          </div>

          <nav className="ml-8 flex h-16 items-center justify-between  border-t border-b border-black py-3 pl-20">
            <div className="divide-x divide-black text-base">
              <button onClick={onClickProject} className="py-1  px-6 uppercase">
                Project
              </button>
              <button onClick={onClickNFTism} className="py-1 px-6 uppercase">
                NFTISM
              </button>
              <button onClick={onClickGallery} className="py-1 px-6 uppercase">
                GALLERY
              </button>
              <button onClick={onClickRoadmap} className="py-1 px-6 uppercase">
                ROADMAP
              </button>
              <button onClick={onClickTeam} className="py-1 px-6 uppercase">
                TEAM
              </button>
              <button onClick={onClickFAQ} className="py-1 px-6 uppercase">
                FAQ
              </button>
            </div>

            <div className="flex space-x-8">
              <InstagramLink />
              <TwitterLink />
              <DiscordLink />

              <div className="relative">
                <OpenSeaButton
                  externalURL="https://opensea.io/collection/heroduck"
                  className="relative z-20 mt-[5px]"
                />
                <div className="absolute top-0 right-0 h-full w-8 bg-brand-1" />
              </div>
            </div>
          </nav>
        </div>
      </div>

      {/* 모바일용 탑바 */}
      <div className="md:hidden">
        <button
          onClick={() => navigate('/')}
          className="absolute top-7 left-4 z-[100]"
        >
          <LogoIcon />
        </button>

        <motion.nav
          initial={false}
          animate={isOpen ? 'open' : 'closed'}
          ref={containerRef}
          className="sticky top-0 z-[100]"
        >
          <motion.div
            className="fixed inset-0 z-30 w-full bg-brand-1"
            variants={sidebar}
          />
          <MobileGNB
            onClickProject={onClickProject}
            onClickGallery={onClickGallery}
            onClickNFTism={onClickNFTism}
            onClickRoadmap={onClickRoadmap}
            onClickTeam={onClickTeam}
            onClickFAQ={onClickFAQ}
            toggle={() => toggleOpen()}
            isOpen={isOpen}
          />
          <MobileGNBButton toggle={() => toggleOpen()} />
        </motion.nav>
      </div>
    </>
  );
};
