import './style.css';

export const GlitchText = () => {
  return (
    <div className="absolute right-0 top-10 md:right-[12%] md:top-[21%]">
      <div className="environment"></div>
      <h1
        className="hero glitch layers font-grotesque text-[350%] md:text-[112px]"
        data-text="PIONEER"
      >
        <span className="relative z-10">PIONEER</span>
        <p className="absolute left-1 top-1 text-[#E34713]">PIONEER</p>
      </h1>
    </div>
  );
};
