import { Route, Routes } from 'react-router-dom';
import { LandingPage } from './LandingPage';

export const Router = () => {
  return (
    <div className="flex min-h-screen flex-1 flex-col">
      <main className="relative flex flex-1 flex-col">
        <Routes>
          <Route path="/" element={<LandingPage />} />
        </Routes>
      </main>
    </div>
  );
};
