import { FC } from 'react';
import { Icon } from '../Icon';

interface TwitterLinkProps {
  className?: string;
}

export const TwitterLink: FC<TwitterLinkProps> = ({ className }) => {
  return (
    <button
      className={className}
      onClick={() => {
        window.open('https://twitter.com/hero_ducks', '_blank');
      }}
    >
      <Icon.Twitter className="h-full w-full" />
    </button>
  );
};
