import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PNG_Text1_EN_Mobile from '../../assets/svg/team/content_en_m_1.png';
import PNG_Text2_EN_Mobile from '../../assets/svg/team/content_en_m_2.png';
import PNG_Text1_KO_Mobile from '../../assets/svg/team/content_ko_m_1.png';
import PNG_Text2_KO_Mobile from '../../assets/svg/team/content_ko_m_2.png';
import PNG_IDCard from '../../assets/svg/team/id_card.png';
import PNG_Paint from '../../assets/svg/team/paint.png';
import PNG_Text_EN from '../../assets/svg/team/team_EN.png';
import PNG_Text_KO from '../../assets/svg/team/team_KO.png';

interface TeamSectionProps {
  sectionRef?: any;
}

export const TeamSection: FC<TeamSectionProps> = ({ sectionRef }) => {
  const { i18n } = useTranslation();

  return (
    <div ref={sectionRef}>
      <div className="hidden overflow-hidden bg-[#222222] md:block">
        <div className="relative z-10 mx-auto h-[600px] max-w-screen-lg ">
          {i18n.language === 'en' ? (
            <img
              src={PNG_Text_EN}
              alt=""
              className="absolute top-1/2 z-10 -translate-y-1/2"
            />
          ) : (
            <img
              src={PNG_Text_KO}
              alt=""
              className="absolute top-1/2 z-10 -translate-y-1/2"
            />
          )}
          <img
            src={PNG_IDCard}
            alt=""
            className=" absolute -left-64 -bottom-3 h-[380px] w-[500px] lg:-left-72 lg:h-[480px] lg:w-[600px]"
          />
        </div>
      </div>
      <div className="block bg-[#222222] md:hidden">
        <div className="relative h-[580px] overflow-hidden">
          {i18n.language === 'en' ? (
            <>
              <img
                src={PNG_Text1_EN_Mobile}
                alt=""
                className="absolute left-1/2 z-10 w-10/12 max-w-sm -translate-x-1/2 pt-5"
              />
              <img
                src={PNG_Text2_EN_Mobile}
                alt=""
                className="absolute top-[19rem] right-5 z-10 ml-auto w-7/12 max-w-xs sm:right-20"
              />
            </>
          ) : (
            <>
              <img
                src={PNG_Text1_KO_Mobile}
                alt=""
                className="absolute left-1/2 z-10 w-full max-w-sm -translate-x-1/2 pt-10"
              />
              <img
                src={PNG_Text2_KO_Mobile}
                alt=""
                className="absolute top-80 right-5 z-10 ml-auto w-7/12 max-w-[17rem] sm:right-20"
              />
            </>
          )}
          <img
            src={PNG_IDCard}
            alt=""
            // style={{-moz-fit-content}}
            className="absolute top-56 -left-48 h-[300px] w-[400px] sm:-left-32 sm:h-[350px] sm:w-[450px]"
          />
          <img
            src={PNG_Paint}
            alt=""
            className="absolute -right-5 bottom-7 w-32"
          />
        </div>
      </div>
    </div>
  );
};
