import { FC } from 'react';
import { useTranslation } from 'react-i18next';
import PNG_Eyeball1 from '../../assets/svg/nftism/eyeball-1.png';
import PNG_Eyeball2 from '../../assets/svg/nftism/eyeball-2.png';
import PNG_Eyeball3 from '../../assets/svg/nftism/eyeball-3.png';
import PNG_Book from '../../assets/svg/nftism/item_book.png';
import PNG_Text1_EN from '../../assets/svg/nftism/item_content_en.png';
import PNG_Text1_KO from '../../assets/svg/nftism/item_content_ko.png';
import PNG_Duck from '../../assets/svg/nftism/item_duck.png';
import PNG_RedBrush_Mobile from '../../assets/svg/nftism/item_eyes_red.png';
import PNG_Frame from '../../assets/svg/nftism/item_frame.png';
import PNG_Mouth from '../../assets/svg/nftism/item_mouth.png';
import PNG_DuckMouth_1 from '../../assets/svg/nftism/item_mouth_2.png';
import PNG_Net from '../../assets/svg/nftism/item_net.png';
import PNG_DuckMouth_2 from '../../assets/svg/nftism/item_only_mouth.png';
import PNG_RedBrush from '../../assets/svg/nftism/item_red-brush.png';
import PNG_Text2_EN from '../../assets/svg/nftism/item_text.png';
import PNG_Text2_EN_Mobile from '../../assets/svg/nftism/item_text_en_m.png';
import PNG_Text2_KO from '../../assets/svg/nftism/item_text_ko.png';
import PNG_Text2_KO_Mobile from '../../assets/svg/nftism/item_text_ko_m.png';
import PNG_Thread_M from '../../assets/svg/nftism/item_thread-m.png';
import PNG_Thread from '../../assets/svg/nftism/item_thread.png';

interface NFTismThirdSectionProps {
  sectionRef?: any;
}

export const NFTismThirdSection: FC<NFTismThirdSectionProps> = ({
  sectionRef,
}) => {
  const { i18n } = useTranslation();

  return (
    <div ref={sectionRef}>
      {/* Desktop */}
      <div className="hidden md:block">
        <div className="relative h-[900px] overflow-hidden bg-brand-1">
          <div className="relative mx-auto h-full max-w-screen-lg">
            <img
              src={PNG_Thread}
              alt=""
              className="absolute top-1/2 left-1/2 max-w-screen-xl -translate-y-1/2 -translate-x-1/2"
            />

            <div className="relative">
              <img
                src={PNG_Book}
                alt=""
                className="absolute top-0 left-0 w-[450px]"
              />
              <img
                src={PNG_Eyeball1}
                alt=""
                className="rotate absolute top-[300px] left-[10%] w-[110px] animate-spin-slow"
              />
            </div>
            <img
              src={PNG_Frame}
              alt=""
              className="absolute right-44 top-0 w-[350px] "
            />
            <img
              src={PNG_Mouth}
              alt=""
              className="absolute bottom-0 -left-24 w-[400px] "
            />
            <div className="relative">
              <img
                src={PNG_DuckMouth_1}
                alt=""
                className="absolute top-16 right-32 z-10 w-[180px]"
              />
              <img
                src={PNG_Eyeball2}
                alt=""
                className="absolute top-10 right-14 z-10 w-[104px] animate-spin-slow"
              />
            </div>

            <div className="">
              <img
                src={PNG_RedBrush}
                alt=""
                className="absolute bottom-10 right-0 w-[180px] "
              />
              <img
                src={PNG_Eyeball3}
                alt=""
                className="absolute bottom-28 right-10 w-[120px] animate-spin-slow"
              />
            </div>
            <img
              src={PNG_Duck}
              alt=""
              className="absolute -left-14 bottom-48 w-[160px]"
            />
            {i18n.language === 'en' ? (
              <>
                <img
                  src={PNG_Text1_EN}
                  alt=""
                  className="absolute left-1/2 top-28 bottom-0 w-[500px] -translate-x-1/4  "
                />
                <img
                  src={PNG_Text2_EN}
                  alt=""
                  className="absolute bottom-0 left-1/2 w-[600px] -translate-x-1/2"
                />
              </>
            ) : (
              <>
                <img
                  src={PNG_Text1_KO}
                  alt=""
                  className="absolute left-1/2 top-28 bottom-0 w-[500px] -translate-x-1/4  "
                />
                <img
                  src={PNG_Text2_KO}
                  alt=""
                  className="absolute bottom-0 left-1/2 w-[600px] -translate-x-1/2"
                />
              </>
            )}
          </div>
        </div>
      </div>

      {/* Mobile */}
      <div className="block overflow-hidden md:hidden">
        <div className="relative h-[900px] bg-brand-1">
          <div className="relative mx-auto h-full max-w-screen-lg">
            <img
              src={PNG_Thread_M}
              alt=""
              className="absolute top-[30%] left-1/2 w-full max-w-screen-sm  -translate-y-1/2 -translate-x-1/2"
            />

            <div className="relative">
              <img
                src={PNG_Book}
                alt=""
                className="absolute top-5 -left-8 z-10 w-[330px]"
              />
              <img
                src={PNG_Eyeball1}
                alt=""
                className="rotate absolute top-[270px] left-[20%] z-50 w-[45px] animate-spin-slow"
              />
            </div>
            <img
              src={PNG_Duck}
              alt=""
              className="absolute left-52 top-[9.5rem] w-[90px]  "
            />

            <img
              src={PNG_Frame}
              alt=""
              className="absolute top-36 -right-5 w-[250px] "
            />

            <div className="relative">
              <img
                src={PNG_DuckMouth_1}
                alt=""
                className="absolute top-56 right-10 z-10 w-[130px] sm:right-32"
              />
              <img
                src={PNG_Eyeball2}
                alt=""
                className="absolute top-52 right-5 z-10 w-[60px] animate-spin-slow"
              />
            </div>

            <img
              src={PNG_RedBrush_Mobile}
              alt=""
              className="absolute bottom-0 right-0 w-[90px] "
            />
            <img
              src={PNG_Eyeball3}
              alt=""
              className="absolute bottom-10 right-8 w-[53px] animate-spin-slow"
            />

            <img
              src={PNG_DuckMouth_2}
              alt=""
              className="absolute bottom-5 -left-2 w-[100px] "
            />
            <img
              src={PNG_Net}
              alt=""
              className="absolute top-[30rem] -left-5 w-[180px] "
            />
            {i18n.language === 'en' ? (
              <>
                <img
                  src={PNG_Text1_EN}
                  alt=""
                  className="absolute top-[17rem] left-[57%] bottom-0 w-[430px] -translate-x-1/2 "
                />
                <img
                  src={PNG_Text2_EN_Mobile}
                  alt=""
                  className="absolute top-[39rem] left-1/2 w-10/12 max-w-lg -translate-x-1/2"
                />
              </>
            ) : (
              <>
                <img
                  src={PNG_Text1_KO}
                  alt=""
                  className="absolute top-[17rem] left-[57%] bottom-0 w-[430px] -translate-x-1/2  "
                />
                <img
                  src={PNG_Text2_KO_Mobile}
                  alt=""
                  className="absolute top-[39rem] left-1/2 w-10/12 max-w-lg -translate-x-1/2"
                />
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
