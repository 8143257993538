import { ButtonHTMLAttributes, FC, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

export interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  text?: string;
  to?: string;
  icon?: ReactElement;
}

export const Button: FC<ButtonProps> = ({
  children,
  className = '',
  text,
  to,
  icon,
  onClick,
  ...props
}) => {
  const navigate = useNavigate();

  return (
    <button
    className={`button ${
      icon && 'flex items-center justify-between'
    } gap-x-2 ${className}`}
    onClick={to ? () => navigate(to) : onClick}
    {...props}
  >
    <p>{text ?? children}</p>
    {icon}
  </button>
  );
};
